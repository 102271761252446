<script setup lang="ts">
import { endOfDay } from 'date-fns'
import { computed, inject, reactive, ref, unref } from 'vue'
import { formatDisplayDate } from '../composables/date'
import Tooltip from './Tooltip.vue'

type CaseInsensitiveUnion<T extends string> = T | Uppercase<T> | Lowercase<T>

type Model = CaseInsensitiveUnion<
  | 'Diagnosis'
  | 'modifier'
  | 'PlaceOfService'
  | 'Procedure'
  | 'Provider'
  | 'specialty'
  | 'edittype'
  | 'ReasonCodeMetadata'
  | 'ClaimType'
>

const props = defineProps<{
  code: string
  description?: string
  disabled?: boolean
  effective_date_end?: string
  is_deleted?: boolean
  model: Model
  name?: string
  displayName?: string
  useDescriptionOnly?: boolean
}>()

interface CodeDetails {
  code?: string
  name?: string
  description?: string
  is_deleted?: boolean
  effective_date_end?: string
}
const emit = defineEmits(['error'])

const getCodeDetails =
  inject<(arg1: string, arg2: string) => CodeDetails>('getRefData')

const state = reactive({
  disabled: !!props.disabled,
  loading: false,
  error: '',
})

const codeDetails = ref<CodeDetails | null>(null)

const details = computed(() => {
  return {
    ...props,
    ...codeDetails.value,
  }
})

const dateEnd = computed(() =>
  details.value.effective_date_end
    ? endOfDay(new Date(details.value.effective_date_end))
    : null,
)

const codeDeleted = computed((): boolean => {
  if (details.value.is_deleted) return true

  return dateEnd.value !== null && dateEnd.value.getTime() < Date.now()
})

const onShow = async () => {
  if (details.value.description || details.value.name || state.error) {
    return
  }

  if (getCodeDetails) {
    try {
      state.loading = true
      const details = await getCodeDetails(props.code, props.model)

      codeDetails.value = details ? unref(details) : null
      state.disabled = !codeDetails.value
    } catch (error) {
      emit('error', error)
      state.error = 'Error fetching description'
    } finally {
      state.loading = false
    }
  } else {
    state.disabled = true
  }
}
</script>

<template>
  <div data-component="ref_data_code" class="inline-block">
    <Tooltip :disabled="state.disabled" @show="onShow">
      <template #activator>
        <slot>
          <div
            class="inline-block"
            :class="{
              'text-secondary-600 hover:text-secondary-800 active:text-secondary-900':
                !state.disabled,
            }"
          >
            {{ displayName || code }}
          </div>
        </slot>
      </template>

      <div class="p-2">
        <div v-if="state.loading" class="animate-pulse">
          <div class="flex w-full items-center">
            <div>
              <span class="subtitle-2">{{ code }}</span>
              <span class="mx-1">-</span>
              {{ codeDetails }}
            </div>
            <div
              class="w-prose h-2 flex-1 overflow-hidden rounded-sm bg-neutral-200 text-neutral-200"
            />
          </div>
          <div class="mb-[6px] h-2 rounded-sm bg-neutral-200" />
          <div class="h-2 w-[80%] rounded-sm bg-neutral-200" />
        </div>
        <p v-else class="max-w-prose">
          <span v-if="!props.useDescriptionOnly" class="subtitle-2">
            {{ code }}
          </span>
          <span
            v-if="!state.error && !props.useDescriptionOnly"
            class="body-2 mx-1"
          >
            -
          </span>
          <span v-if="details.name && !props.useDescriptionOnly" class="body-2">
            {{ details.name }}
          </span>
          <br
            v-if="
              details.name && details.description && !props.useDescriptionOnly
            "
            class="mb-2"
          />
          <span v-if="details.description" class="body-2">
            {{ details.description }}
          </span>
        </p>
        <p v-if="codeDeleted" class="body-2 max-w-prose pt-2">
          [Deleted code, eff. {{ formatDisplayDate(dateEnd) }}]
        </p>
        <p v-if="state.error" class="body-2 text-error-light">
          {{ state.error }}
        </p>
      </div>
    </Tooltip>
  </div>
</template>
