<script
  setup
  generic="
    T extends {
      disableSelect?: boolean
      id: string
      secondaryHighlight?: boolean
    }
  "
  lang="ts"
>
import type { MaybeElement } from '@vueuse/core'
import { useElementBounding, useScroll } from '@vueuse/core'
import { useSlots } from 'vue'
import { computed, ref, watch } from '#imports'
import { useLayoutState } from '../composables/layout'
import { scrollToId } from '../composables/scrollIntoView'
import type { SortState, TableHeader } from '../composables/table'
import { useTableSort } from '../composables/table'
import Button from './Button.vue'
import Loading from './Loading.vue'
import THeader from './TableHeader.vue'
import TransitionFade from './transition/Fade.vue'

const props = defineProps<{
  activeId?: string
  activeKey?: string
  /* data needs an id key */
  data: T[]
  error?: string
  hasFirstDataLoaded?: boolean
  hasActiveFilters?: boolean
  headers: TableHeader<T>[]
  hideCheckboxes?: boolean
  hideEndMessage?: boolean
  loading?: boolean
  modelValue?: Set<string>
  noResults?: boolean
  removePadding?: boolean
  removePointer?: boolean
  sortOptions: SortState<keyof T>
  sticky?: boolean
  stickyOffset?: number
}>()

const emit = defineEmits<{
  'click:item': [item: T]
  'reset-filters': []
  'select-all': []
  sort: [state: SortState<T>]
  'update:modelValue': [value: Set<string>]
}>()

defineSlots<
  {
    [I in `item.${string}`]?: (_: {
      activeId?: T['id']
      header: TableHeader<T>
      item: T
    }) => any
  } & {
    [H in `header.${string}`]?: () => any
  } & {
    append?: () => any
    'empty-message'?: () => any
    error?: () => any
    'nodata-message'?: () => any
    prepend?: () => any
  }
>()

const slots = useSlots()
const { isScrolling } = useLayoutState()

const containerEl = ref<MaybeElement>()
const scrollEl = ref<HTMLElement>()

const { arrivedState } = useScroll(scrollEl)
const { y } = useElementBounding(containerEl, { windowResize: false })

const translateY = computed<number>(() => {
  if (!props.sticky) return 0

  const offset = props.stickyOffset || 0

  if (y.value < offset) {
    return offset - y.value
  }
  return 0
})
const theadStyle = computed(() => {
  if (!props.sticky) {
    return {}
  }

  if (!props.data?.length || translateY.value === 0) {
    return {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
      'will-change': 'transform, opacity',
      'transition-duration': '0ms',
      position: 'sticky',
    }
  }

  if (isScrolling.value) {
    return {
      opacity: 0,
      transform: `translate3d(0, ${translateY.value - 40}px, 0)`,
      'will-change': 'transform, opacity',
      'transition-duration': '50ms',
      position: 'sticky',
    }
  }

  return {
    opacity: 1,
    transform: `translate3d(0, ${translateY.value}px, 0)`,
    'will-change': 'transform, opacity',
    'transition-duration': '200ms',
    position: 'sticky',
  }
})

const tableHeaders = computed<TableHeader<T>[]>(() => {
  let visibleHeaders = props.headers.filter((header) => !header.hidden)
  if (props.hideCheckboxes || !props.modelValue) {
    if (visibleHeaders.some(header => header.key === 'code' && (header.text === 'TIN' || header.text === 'NPI'))) {
      visibleHeaders = visibleHeaders.filter(header => !(header.key === 'name' && header.text === 'Name'))
    }
    return visibleHeaders
  }

  return [
    {
      key: 'checkbox',
      text: '',
      align: 'center',
      disableSort: true,
      skeletonCellSize: '1.5rem',
    },
    ...visibleHeaders,
  ]
})

const tableCellClasses = (header: TableHeader<T>, index: number) => ({
  'pl-4 lg:pl-8': !props.removePadding && index === 0,
  'pr-4 lg:pr-8':
    !props.removePadding && index === tableHeaders.value?.length - 1,
  'table-numbers-1': !header.useDisplayFont,
  'font-display': header.useDisplayFont,
  'font-fixed': header.fixedWidth,
  'text-sm font-medium text-neutral-900 group-hover:underline': header.isTitle,
  'font-semibold': header.isTitle && !header.useDisplayFont,
  'max-w-prose': header.prose,
  'w-px': !header.expand,
  'sticky left-0 bg-surface group-hover:bg-surface-bg after:content-empty after:w-[2px] after:h-full after:bg-neutral-200 after:absolute after:top-0 after:right-0':
    header.sticky,
})

const cellContentClasses = (header: TableHeader<T>) => ({
  'pr-6': !header.disableSort && header.align === 'right',
  'pl-5': !!header.icon && !header.iconOnly,
  'truncate whitespace-nowrap py-3': !header.multiline,
  'justify-start text-left': !header.align || header.align === 'left',
  'justify-center text-center': header.align === 'center',
  'justify-end text-right': header.align === 'right',
})

const { getSortState, setSortState, sortState } = useTableSort<T>(
  props.headers,
  props.sortOptions,
  (state) => emit('sort', state),
)

watch(
  () => props.activeId,
  (id) => scrollToId(id),
  { immediate: true },
)

watch(
  () => props.sortOptions,
  () => {
    if (sortState.value.column !== props.sortOptions.column)
      sortState.value = props.sortOptions
  },
)

const onClickItem = (item: T) => {
  emit('click:item', item)
}

const selectedItems = computed<Set<string>>({
  get() {
    return props.modelValue || new Set()
  },
  set(value: Set<string>) {
    emit('update:modelValue', value)
  },
})

const checkSelected = (id: string) => {
  if (selectedItems.value.has(id)) {
    selectedItems.value.delete(id)
  } else {
    selectedItems.value.add(id)
  }
  emit('update:modelValue', selectedItems.value)
}

const handleSortClick = (key: keyof T, isDisabled: boolean) => {
  if (!isDisabled) setSortState(key)
}

const allSelected = computed({
  get: (): boolean =>
    !!(
      props.data.length &&
      props.modelValue &&
      props.modelValue?.size === props.data.length
    ),
  set: (value: boolean) => {
    if (value) {
      for (const item of props.data) {
        if (!item.disableSelect) selectedItems.value.add(item.id)
      }
      emit('select-all')
    } else {
      selectedItems.value.clear()
    }
  },
})

// TODO: figure out how to properly type this
const getActiveKeyValue = (item: any): string =>
  props.activeKey && Object.hasOwn(item, props.activeKey)
    ? item[props.activeKey].toString()
    : item.id
</script>

<template>
  <section ref="containerEl" class="relative sticky h-full w-full">
    <div
      ref="scrollEl"
      class="snap-proximity overflow-y-auto"
      :class="{
        'snap-x': data?.length > 0 && !arrivedState.left,
      }"
    >
      <table class="min-w-full">
        <thead
          class="bg-surface-bg easing-in z-10 transition"
          :class="{ 'border-b border-neutral-200': !sticky }"
          :style="theadStyle"
        >
          <tr
            :class="{
              'after:content-empty after:absolute after:bottom-0 after:left-0 after:h-px after:w-full after:bg-neutral-200':
                sticky,
            }"
          >
            <template v-for="(header, index) in tableHeaders">
              <!-- w-px ensures that the th doesn't grow wider than it's content -->
              <th
                class="snap-start"
                :class="{
                  'min-w-prose': header.prose && !data?.length,
                  'w-px': !header.expand,
                  'bg-surface-bg after:content-empty sticky left-0 after:absolute after:top-0 after:right-0 after:h-full after:w-[2px] after:bg-neutral-200':
                    header.sticky,
                }"
              >
                <div
                  v-if="header.key === 'checkbox' && index === 0"
                  class="flex w-full items-center justify-center pr-2 text-center lg:pr-3"
                  :class="{
                    'pl-4 lg:pl-8': !removePadding,
                  }"
                >
                  <input
                    :key="`checkbox-all-${allSelected}-${data?.length}`"
                    v-model="allSelected"
                    class="checkbox body-1"
                    :class="{
                      'checkbox-indeterminate':
                        data.length && selectedItems?.size > 0 && !allSelected,
                    }"
                    aria-label="Checkbox for all items"
                    type="checkbox"
                  />
                </div>
                <div
                  v-else
                  class="flex"
                  :class="{
                    'justify-start text-left':
                      !header.align || header.align === 'left',
                    'justify-center text-center': header.align === 'center',
                    'justify-end text-right': header.align === 'right',
                  }"
                >
                  <THeader
                    class="px-2 font-light text-neutral-800 lg:px-3"
                    :class="{
                      'pl-4 lg:pl-8': !removePadding && index === 0,
                      'pr-4 lg:pr-8':
                        !removePadding && index === tableHeaders?.length - 1,
                    }"
                    :hide-sort="header.disableSort"
                    :sort="getSortState(header.sortKey || header.key)"
                    @click="
                      handleSortClick(
                        header.sortKey || header.key,
                        header.disableSort || false,
                      )
                    "
                  >
                    <div
                      v-if="header.icon"
                      class="mr-1 inline-block h-4 w-4"
                      :class="{ [header.icon]: true, 'mr-1': header.iconOnly }"
                    />
                    <slot :name="`header.${String(header.key)}`">
                      <span
                        v-if="!header.iconOnly"
                        class="subtitle-2 font-light"
                      >
                        {{ header.text }}
                      </span>
                    </slot>
                  </THeader>
                </div>
              </th>
            </template>
            <!-- td instead of th for accessability -->
            <td v-if="!removePadding" />
          </tr>
        </thead>
        <tbody
          class="bg-surface divide-y divide-neutral-100 border-b border-neutral-100"
        >
          <slot name="prepend" />
          <tr
            v-for="_skeletonResult in 30"
            v-if="!hasFirstDataLoaded && loading"
            class="animate-pulse"
          >
            <td
              v-for="(skeletonHeader, sIndex) in tableHeaders"
              class="px-2 lg:px-3"
              :class="tableCellClasses(skeletonHeader, sIndex)"
            >
              <div class="flex" :class="cellContentClasses(skeletonHeader)">
                <span
                  class="h-5.5 rounded-lg bg-gray-200"
                  :style="{ width: skeletonHeader.skeletonCellSize || '75%' }"
                />
              </div>
            </td>
            <td v-if="!removePadding" />
          </tr>
          <tr
            v-for="(item, index) in data"
            v-else
            :id="item.id"
            :key="`data_table_tr-${item.id}-${index}`"
            class="group text-sm"
            :class="{
              'bg-primary-50':
                getActiveKeyValue(item) && activeId === getActiveKeyValue(item),
              'hover:bg-surface-bg':
                getActiveKeyValue(item) && activeId !== getActiveKeyValue(item),
              'bg-secondary-50': item.secondaryHighlight,
              'cursor-default': removePointer,
              'cursor-pointer': !removePointer,
            }"
            @click="onClickItem(item)"
          >
            <!-- w-px ensures that the td doesn't grow wider than it's content -->
            <td
              v-for="(header, hIndex) in tableHeaders"
              class="px-2 text-sm lg:px-3"
              :class="tableCellClasses(header, hIndex)"
            >
              <div :class="cellContentClasses(header)">
                <div
                  v-if="header.key === 'checkbox' && hIndex === 0"
                  class="flex h-full items-center px-4"
                >
                  <input
                    :key="`checkbox-${item.id}-${selectedItems.has(item.id)}`"
                    v-model="selectedItems"
                    class="checkbox"
                    :aria-label="`Checkbox for ${item.id}`"
                    :disabled="item.disableSelect"
                    :value="item.id"
                    type="checkbox"
                    @click.stop.prevent="checkSelected(item.id)"
                  />
                </div>
                <slot
                  v-else-if="slots[`item.${String(header.key)}`]"
                  v-bind="{ header, item, activeId }"
                  :name="`item.${String(header.key)}`"
                />
                <span v-else>
                  {{ item[header.cellText || header.key] || '–' }}
                </span>
              </div>
            </td>
            <td v-if="!removePadding" />
          </tr>
          <slot name="append" />
        </tbody>
      </table>
    </div>
    <!-- Data table state -->
    <ul v-if="data?.length">
      <li v-if="hasFirstDataLoaded && loading" class="flex justify-center p-4">
        <Loading class="text-secondary h-8" />
      </li>
      <li v-else-if="!hideEndMessage">
        <div class="border-t border-neutral-100 px-4 pt-4 text-center">
          <p class="body-2 text-neutral-700">
            You have reached the end of the results.
          </p>
        </div>
      </li>
    </ul>

    <div v-else>
      <TransitionFade mode="out-in">
        <div
          v-if="hasFirstDataLoaded && loading"
          class="h-60vh flex flex-1 items-center justify-center overflow-hidden transition-opacity"
        >
          <div class="flex space-x-2">
            <Loading class="text-secondary h-6" />
            <p class="h2 text-neutral-900">Loading...</p>
          </div>
        </div>
        <div v-else-if="error" class="h-40vh flex items-center justify-center">
          <div class="flex flex-1 items-center justify-center">
            <div class="text-center">
              <slot name="error">
                <div
                  class="bg-error-light flex flex-col items-center space-y-1 rounded-lg p-4 text-center"
                >
                  <h2 class="h2 text-error-dark">Error</h2>
                  <p
                    class="body-1 text-error max-w-80vw overflow-hidden pb-3 text-ellipsis"
                  >
                    {{ error }}
                  </p>
                </div>
              </slot>
            </div>
          </div>
        </div>
        <div
          v-else-if="hasActiveFilters && noResults"
          class="flex flex-1 items-center justify-center"
        >
          <slot name="nodata-message">
            <div
              class="h-40vh flex flex-col items-center justify-center space-y-1 text-center"
            >
              <h2 class="h2 text-neutral-900">No data found</h2>
              <p class="body-1 pb-3 text-neutral-700">
                There's no data to display here for the selected line of
                business.
              </p>
              <Button class="mt-2" size="xs" @click="$emit('reset-filters')">
                Reset filters
              </Button>
            </div>
          </slot>
        </div>
        <div
          v-else-if="noResults"
          class="flex flex-1 items-center justify-center"
        >
          <slot name="empty-message">
            <div
              class="h-40vh flex flex-col items-center justify-center space-y-1 text-center"
            >
              <h2 class="h2 text-neutral-900">No results</h2>
              <p class="body-1 pb-3 text-neutral-700">
                Nothing was found that matches your search.
              </p>
            </div>
          </slot>
        </div>
      </TransitionFade>
    </div>
  </section>
</template>
