import type { SortDirection } from '@rialtic/types'
import { defu } from 'defu'
import type { UnwrapRef } from 'vue'
import { ref } from '#imports'

export interface TableHeader<T> {
  /** Align the content of the column */
  align?: 'left' | 'right' | 'center'
  /** Will override the value of the object's `key` to show this  */
  cellText?: keyof T
  /** Disables sorting for this column */
  disableSort?: boolean
  /** When true the column will expand/fill available space */
  expand?: boolean
  /** Use the fixed width font */
  fixedWidth?: boolean
  /** When true this will hide the whole column */
  hidden?: boolean
  /** Adds an icon to the header */
  icon?: string
  /** Hides the text in the column header */
  iconOnly?: boolean
  isTitle?: boolean
  /** The key name of the property on the item object */
  key: keyof T
  multiline?: boolean
  /** This will give a max width of w-prose */
  prose?: boolean
  /** Optional, use this to sort on something other than `key` */
  sortKey?: keyof T
  sticky?: boolean
  text: string
  /**
   * Will set the font to display (Poppins)
   * @optional
   */
  useDisplayFont?: boolean
  skeletonCellSize?: string
}

export interface SortState<U> {
  column: U
  sort: SortDirection
}

export const useTableSort = <T>(
  headers: TableHeader<T>[],
  options: SortState<TableHeader<T>['key']>,
  callback?: (sortState: SortState<T>) => void,
) => {
  const sortState = ref<SortState<TableHeader<T>['key']>>(
    defu(options, {
      column: headers?.find(({ disableSort }) => !disableSort)?.key ?? '',
      sort: 'asc' as SortDirection,
    }),
  )

  const getSortState = (name: UnwrapRef<keyof T>) => {
    return sortState.value.column === name ? sortState.value.sort : undefined
  }

  const setSortState = (name: keyof T) => {
    sortState.value =
      sortState.value.column === name
        ? { ...sortState.value, sort: toggleSort(sortState.value.sort) }
        : { column: name, sort: 'asc' }
    if (callback) {
      callback(sortState.value)
    }
  }

  const nextSort: Record<string, SortDirection | undefined> = {
    asc: 'desc',
    desc: undefined,
  }
  const toggleSort = (sort: SortDirection): SortDirection =>
    (sort && nextSort[sort]) ?? 'asc'

  return {
    getSortState,
    setSortState,
    sortState: sortState,
  }
}
