import type { Api } from '@rialtic/api'

export const getRecommendedInsight = (
  insights: Api.ProcessedInsight[] = [],
): Api.ProcessedInsight | undefined => {
  if (!Array.isArray(insights) || !insights.length) return

  const [recommended] = insights
    .filter((insight) => insight.is_recommendation)
    .sort(
      (a, b) =>
        Number.parseInt(b.raw_insight_id, 10) -
        Number.parseInt(a.raw_insight_id, 10),
    )
  return recommended
}

export const getInsightDecision = (
  recommendedInsight?: Api.ProcessedInsight,
) => {
  if (!recommendedInsight) return
  return {
    status: recommendedInsight.insight.insight.type,
    reason: recommendedInsight.insight.insight.description,
  }
}

export const getInsightBadgeLabel = (
  recommendedInsight?: Api.ProcessedInsight,
) => {
  if (!recommendedInsight) return
  return recommendedInsight?.insight?.insight?.type ===
    'Claim Line Partially Payable'
    ? 'Modify'
    : 'Not payable'
}
